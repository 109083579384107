<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close' | 'action'): void
}>()

const { t } = useI18n()

const businessStore = useBusinessStore()
</script>

<template>
  <base-dialog
    data-cy="leave_business_dialog"
    max-width="640"
    :title="t('settings.business_optout.dialog.title', { organisation: businessStore.name })"
    :disabled="loading"
    :loading="loading"
    :action-text="t('settings.business_optout.dialog.yes')"
    action-color="error"
    :cancel-text="t('settings.business_optout.dialog.no')"
    @action="emit('action')"
    @close="emit('close')"
  >
    <v-row>
      <v-col cols="12" sm="7">
        <p class="mb-4">
          <strong>
            {{
              t('settings.business_optout.dialog.intro', {
                organisation: businessStore.name,
              })
            }}
          </strong>
        </p>
        <p>
          {{
            t('settings.business_optout.dialog.text', {
              organisation: businessStore.name,
            })
          }}
        </p>
      </v-col>
      <v-col v-if="$vuetify.display.smAndUp" sm="5">
        <responsive-image source="gtc-dialog-business-leave" :width="208" :height="222" />
      </v-col>
    </v-row>
  </base-dialog>
</template>
